<!--
  PACKAGE_NAME : src\pages\esp\system\security\allowed-ip.vue
  FILE_NAME : allowed-ip
  AUTHOR : supark
  DATE : 2021-07-14
  DESCRIPTION : 접속 허용 IP 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    data() {
      return {
        dataGrid: {
          refName: 'allowedIPRefName',
          keyExpr: 'allowedIPId',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          columnResizingMode: 'nextColumn', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          dataSource: [],
          apiActionNm: {
            merge: 'ALLOWED_IP_LIST_MERGE',
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'IP',
              dataField: 'allowedIP',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: 'IP값은 필수입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'left',
              minWidth: 300,
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록자',
              dataField: 'regId',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              alignment: 'center',
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              alignment: 'center',
            },
            {
              caption: '수정자',
              dataField: 'editId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정 일시',
              dataType: 'date',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 접속허용 IP 목록 조회
       *
       * @return {Promise<{data, totalCount}>}
       */
      async selectAllowedIPList() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'allowedIPId', // store id 설정필요
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params };

            const payload = {
              actionname: 'ALLOWED_IP_LIST_ALL',
              data: params,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              return {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            } else {
              vm.$_Toast(vm.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          },
        });
      },
      /**
       * @description : 접속 허용 IP 삭제
       * @return {Promise<boolean>}
       */
      async onDeleteData() {
        const selectedRowsData = this.$refs.allowedIPRefName.getGridInstance.getSelectedRowsData();
        if (selectedRowsData?.length) {
          if (await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
            const payload = {
              actionname: 'ALLOWED_IP_LIST_DELETE',
              data: {
                data: selectedRowsData.map(item => item.allowedIPId),
              },
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$refs.allowedIPRefName.refreshData();
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          }
        } else {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
    },
    mounted() {
      this.selectAllowedIPList();
    },
  };
</script>
